
<template>
  <v-container fluid>
    <DataExportIndex></DataExportIndex>
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
//import validations from '@/mixins/validations'
//import Api from '@/api/index';

export default {
  name: 'DataExport',
  //mixins: [ validations ],
  components: {
    DataExportIndex: () => import('@/components/data_export/DataExportIndex.vue'),
  },
  data() {
    return {
      
    }
  },
  methods: {
    name() {
      
    },
    ...mapActions({
      // function: 'Module/action'
      //addNotification: 'Notification/add'
    })
  },
  computed: {
    ...mapState({
      // data: state=> state.Module.state_var
    })
  },
}
</script>

<style scoped>

</style>